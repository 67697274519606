import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layout/layout';
import { Container } from 'reactstrap';
import { TextBlock } from '../../../components/shared/text-block';
import { PRIMARY_TEXT_COLOR } from '../../../constants';
import { Wrapper } from '../../../components/shared/wrapper';
import { Fullscreen } from '../../../components/fullscreen/fullscreen';
import { Background } from '../../../components/background/background';
import cover from '../../../../static/images/services/cover.png';
import { BadgeLanguage, TitleContainer, TitleText } from '../../../components/shared';
import Link from '../../../components/shared/link';
import * as QueryString from 'query-string';
import styled from 'styled-components';
import { navigate } from "@reach/router"

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 370px) {
        flex-direction: column;
    }
    justify-content: start;
    align-items: start;
`;

const Designer = props => {
    const [state, setState] = useState(true);

    useEffect(() => {
        let parsed = QueryString.parse(props.location.search);
        if (parsed.language === 'sl') {
            setState(false);
        }
    }, [props.location.search]);

    // remove to make UI/UX designer job application accessible
    useEffect(() => {
        navigate('/');
    }, []);

    const changeLanguage = language => {
        if (state !== language) {
            window.location = `${window.location.pathname}?language=${language ? 'eng' : 'sl'}`;
        }
    };
    return (
        <Layout>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <Background bg={cover} design={'cover'} index={1} />
                <TitleContainer>
                    <TitleText>{state ? 'UX / UI Designer' : 'UX / UI Dizajner'}</TitleText>
                </TitleContainer>
            </Fullscreen>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <BadgeLanguage>
                        <span className={state ? 'black' : null} onClick={() => changeLanguage(true)}>
                            Eng
                        </span>
                        <span className={state ? null : 'black'} onClick={() => changeLanguage(false)}>
                            Slo
                        </span>
                    </BadgeLanguage>
                    {state ? (
                        <>
                            <TextBlock title={''}>
                                <p>
                                    We are looking for a proactive, curious, and responsible co-worker to join our team in Maribor,
                                    Slovenia.
                                </p>
                            </TextBlock>
                            <TextBlock title={'Your challenges'}>
                                <p style={{ margin: '0', padding: '0' }}>UI Design</p>
                                <ul style={{ marginTop: 0 }}>
                                    <li>Designing components for pattern library</li>
                                    <li>Producing high fidelity screens</li>
                                    <li>Creating prototypes</li>
                                </ul>

                                <p style={{ margin: '0', padding: '0' }}>UX Design</p>
                                <ul style={{ marginTop: 0 }}>
                                    <li>Building personas</li>
                                    <li>Interviewing stakeholders and users</li>
                                    <li>Producing product structure and flows</li>
                                    <li>Building web and mobile products wireframes</li>
                                </ul>

                                <p style={{ margin: '0', padding: '0' }}>UX Research</p>
                                <ul style={{ marginTop: 0 }}>
                                    <li>Running Design Sprints</li>
                                    <li>Building personas</li>
                                    <li>Interviewing stakeholders and users</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Your profile'}>
                                <ul>
                                    <li>Minimum 1 year of work experience</li>
                                    <li>Focus on the user experience by understanding business requirements</li>
                                    <li>Creating style guides and ensuring the consistency of digital products</li>
                                    <li>Production of wire models and prototypes to demonstrate the operation of a digital product</li>
                                    <li>Performing user tests</li>
                                    <li>Understanding operations based on agile methods and the Scrum framework</li>
                                    <li>
                                        Work in a development team, which may include other technical experts, field experts, project
                                        stakeholders and executive management
                                    </li>
                                    <li>Cooperation with engineers and product managers</li>
                                    <li>Good knowledge of FIGMA</li>
                                    <li>Good knowledge of Adobe software package.</li>
                                    <li>Conducting design workshops (eg Design sprint and LDS)</li>
                                    <li>Understanding and interpreting visual elements (layout, type and fonts)</li>
                                    <li>Precision and sense for detail and following new trends in UI / UX</li>
                                    <li>Time management and multitasking skills</li>
                                    <li>Creativity and problem-solving ability</li>
                                    <li>Acting in accordance with company policy</li>
                                    <li>Other similar work as directed by the supervisor</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'What we offer'}>
                                <ul>
                                    <li>Work in a connected and highly qualified team</li>
                                    <li>Indefinite employment contract with 6 months probation period</li>
                                    <li>Competitive salary, suitable to experiences</li>
                                    <li>
                                        Work on interesting and recognizable projects (NKBM - mBanka@Net, MindCandy - Moshi Twilight,
                                        Autistica - Molehill Mountain app and others)
                                    </li>
                                    <li>A dynamic and relaxed work environment</li>
                                    <li>Flexible working hours and work from home</li>
                                    <li>Further learning and support from the team</li>
                                    <li>Sports activities (good posture)</li>
                                    <li>
                                        Participation in{' '}
                                        <a href={'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners/?guccounter=1'} rel={"nofollow"}>
                                            hackathons.
                                        </a>
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Are you interested?'}>
                                <p>If the position interest you, make sure to apply as soon as possible!</p>
                                <ButtonWrapper>
                                    <Link to="https://forms.office.com/r/q5eimj2HPE" className="mt-2">
                                        <button className="btn btn-warning mr-2">Apply</button>
                                    </Link>

                                    <Link to="https://forms.office.com/r/NQ4BBQ084M" className="mt-2">
                                        <button className="btn btn-link">I want to know more</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    ) : (
                        <>
                            <TextBlock title={''}>
                                <p>
                                    V našo ekipo vabimo proaktivno, radovedno in odgovorno osebo, ki je vajena ekipnega dela in se ne boji
                                    izzivov. Delo poteka na sedežu podjetja v centru Maribora, z možnostjo občasnega dela na daljavo.
                                </p>
                            </TextBlock>
                            <TextBlock title={'Tvoji izzivi'}>
                                <p style={{ margin: '0', padding: '0' }}>UI Dizajn</p>
                                <ul style={{ marginTop: 0 }}>
                                    <li>Oblikovanje Figma komponent</li>
                                    <li>Oblikovanje končnega dizajna ekranov</li>
                                    <li>Izdelava prototipov</li>
                                </ul>

                                <p style={{ margin: '0', padding: '0' }}>UX Dizajn</p>
                                <ul style={{ marginTop: 0 }}>
                                    <li>Izgradnja “person”</li>
                                    <li>Intervjuvanje ključnih partnerjev in uporabnikov</li>
                                    <li>Izdelava produktnih struktur, informacijskih arhitektur in ključnih potekov</li>
                                    <li>Izdelava žičnih modelov</li>
                                    <li>Izvedba design sprint delavnic</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Tvoj profil'}>
                                <ul>
                                    <li>Minimalno 1 leto delovnih izkušenj</li>
                                    <li>Osredotočenost na uporabniško izkušnjo z razumevanjem poslovnih zahtev</li>
                                    <li>Ustvarjanje slogovnih vodnikov in zagotavljanje konsistentnosti digitalnih produktov</li>
                                    <li>Izdelava žičnih modelov in prototipov za prikaz delovanja digitalnega produkta</li>
                                    <li>Izvedba uporabniških testov</li>
                                    <li>Razumevanje delovanje na podlagi agilnih metod in Scrum framework-a</li>
                                    <li>
                                        Delo v razvojni skupini, ki lahko vključuje druge tehnične strokovnjake, strokovnjake za področje,
                                        zainteresirane deležnike na projektu in izvršno vodstvo
                                    </li>
                                    <li>Sodelovanje z inženirji in vodji izdelkov</li>
                                    <li>Dobro poznavanje programa FIGMA</li>
                                    <li>Dobro poznavanje Adobe programskega paketa.</li>
                                    <li>Izvedba dizajn delavnic (npr. Design sprint in LDS)</li>
                                    <li>Razumevanje in interpretiranje vizualnih elementov (postavitev, vrsta in pisave)</li>
                                    <li>Preciznost in čut za podrobnosti ter sledenje novimi trendom v UI / UX</li>
                                    <li>Sposobnosti upravljanja časa in večopravilnosti</li>
                                    <li>Kreativnost in sposobnost reševanja problemov</li>
                                    <li>Delovanje v skladu s politiko podjetja</li>
                                    <li>Druga podobna dela po navodilih nadrejenega</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Nudimo ti'}>
                                <ul>
                                    <li>Delo v povezani in visoko usposobljenosti ekipi</li>
                                    <li>Poskusna doba 6 mesecev - zaposlitev za nedoločen čas</li>
                                    <li>Konkurenčna plača, primerna izkušnjam</li>
                                    <li>
                                        Delo na zanimivih in prepoznavnih projektih (NKBM - mBanka@Net, MindCandy - Moshi Twilight,
                                        Autistica - Molehill Mountain app in drugi)
                                    </li>
                                    <li>Dinamično in sproščeno delovno okolje</li>
                                    <li>Fleksibilni delovni čas in delo od doma</li>
                                    <li>Spodbujanje nadaljnjega učenja</li>
                                    <li>Športne dejavnosti (telovadba za boljšo držo)</li>
                                    <li>
                                        Sodelovanje na{' '}
                                        <a href={'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners/?guccounter=1'} rel={"nofollow"}>
                                            hackathonih.
                                        </a>
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Te zanima?'}>
                                <p>V kolikor se prepoznate v zgornjem opisu vas vabimo, da se čimprej prijavite!</p>
                                <ButtonWrapper>
                                    <Link to="https://forms.office.com/r/peaTBbv844" className="mt-2">
                                        <button className="btn btn-warning mr-2">Prijava</button>
                                    </Link>
                                    <Link to="https://forms.office.com/r/SzdrNdTctz" className="mt-2">
                                        <button className="btn btn-link">Zanima me več</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    )}
                </Container>
            </Wrapper>
        </Layout>
    );
};

export default Designer;
